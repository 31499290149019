module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-vercel/gatsby-browser.js'),
      options: {"plugins":[],"debug":false},
    },{
      plugin: require('../../../var/task/node_modules/@vercel/gatsby-plugin-vercel-analytics/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
